import React, { useEffect } from 'react'
import './Home.css'
import {
  IconMapPin,
  IconMail,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconArrowDown,
  IconArrowUp,
} from '@tabler/icons-react'
// import FestiPlannr from './FestiPlannr'
// import Everlaw from './Everlaw'
import ReactGA from 'react-ga4'
import './Update.css'
import { motion } from 'framer-motion'
import Marquee from 'react-fast-marquee'
import { testimonialsData } from '../constants'

const fpSet1 = [
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/a0023122-54bf-4b23-41dc-9652f9cc6500/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/1093656d-bf83-4b96-6736-1a3a2d7f5d00/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/35f90651-d008-4aa3-e9e3-68d30457dd00/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/4adb0e3b-80d1-4833-0dff-a5e59e3bcb00/public',
]

const fpSet2 = [
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/debed6f1-4bd7-410d-d7fe-eb29af530900/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/9cf2f5ff-36c0-449d-babe-17b2650e9f00/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/a5852d3f-b5a4-4dbf-5b4c-7d1007044300/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/2cb13904-45a4-4caa-68d8-6c0e27fa8500/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/2f8efea5-c878-466d-f285-832ffe1e6600/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/0ebc250c-1e36-4013-a665-757827ce2100/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/f111f5a5-000f-4d28-9d87-6a23ca268400/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/f95d2eb6-6179-43e4-1098-880210a56800/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/64de9ac9-374a-495a-d097-3496c6dd3900/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/f0751039-f6cf-4b0b-39fe-f6d9bf53cc00/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/2d819a94-77bc-4a09-03c9-46922fe97f00/public',
  'https://imagedelivery.net/0_FV8lAr1kp9plna3CH44g/dabae4ee-6388-4a30-e0db-9dc57339ec00/public',
]

const Home = () => {
  // const [scrollProgress, setScrollProgress] = useState(0)

  const handleLinkClick = (link) => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked on External Link',
      label: link,
    })
  }
  const handleActionClick = (action) => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked on Action',
      label: action,
    })
  }

  useEffect(() => {
    const calculateImgsetHeights = () => {
      const imgsets = document.querySelectorAll('.u-imgset')

      imgsets.forEach((imgset) => {
        const imgs = imgset.querySelectorAll('.u-img')
        const gap = 8
        const numImages = imgs.length

        const totalAspectRatio = Array.from(imgs).reduce((sum, img) => {
          return sum + img.naturalWidth / img.naturalHeight
        }, 0)

        const containerWidth = imgset.offsetWidth - gap * (numImages - 1)
        const baseHeight = containerWidth / totalAspectRatio

        imgs.forEach((img) => {
          const aspectRatio = img.naturalWidth / img.naturalHeight
          const width = baseHeight * aspectRatio

          img.style.height = `${baseHeight}px`
          img.style.width = `${width}px`
          img.style.objectFit = 'cover'
        })
      })
    }

    const handleImagesLoaded = () => {
      calculateImgsetHeights()
    }

    const imgElements = document.querySelectorAll('.u-img')
    imgElements.forEach((img) => {
      if (img.complete) {
        handleImagesLoaded()
      } else {
        img.addEventListener('load', handleImagesLoaded)
      }
    })

    window.addEventListener('resize', calculateImgsetHeights)

    return () => {
      imgElements.forEach((img) => {
        img.removeEventListener('load', handleImagesLoaded)
      })
      window.removeEventListener('resize', calculateImgsetHeights)
    }
  }, [])

  return (
    <div className="home-wrapper">
      <div className="splash-wrapper">
        <div className="splash-content-wrapper">
          <motion.div
            className="splash-left"
            initial={{
              opacity: 0,
              x: 24,
            }}
            animate={{
              opacity: 1,
              filter: 'blur(0px)',
              x: 0,
            }}
            transition={{
              delay: 0.4,
              duration: 0.67,
              timing: 'ease-in',
            }}
          >
            <h1>James Feng</h1>
            <div className="splash-icons-wrapper">
              {/* <a
                onClick={() => handleLinkClick('Resume')}
                href="https://drive.google.com/file/d/1-gkM7l7Z4WVsci_FAtpdfKEn7qtW6uZS/view?usp=sharing"
                target="_blank"
                rel="noreferrer"
              >
                <IconFileText
                  size={25}
                  strokeWidth={1.68}
                  className="splash-icon icon-resume"
                />
              </a> */}
              <a
                onClick={() => handleLinkClick('LinkedIn')}
                href="https://www.linkedin.com/in/james-feng/"
                target="_blank"
                rel="noreferrer"
              >
                <IconBrandLinkedin
                  size={28}
                  strokeWidth={1.5}
                  className="splash-icon icon-linkedin"
                />
              </a>
              {/* <a
                onClick={() => handleLinkClick('Github')}
                href="https://github.com/fengJames"
                target="_blank"
                // rel="noreferrer"
              >
                <IconBrandGithub
                  size={25}
                  strokeWidth={1.68}
                  className="splash-icon icon-github"
                />
              </a> */}
              <a
                onClick={() => handleLinkClick('Instagram')}
                href="https://www.instagram.com/famousjenga"
                target="_blank"
                rel="noreferrer"
              >
                <IconBrandInstagram
                  size={28}
                  strokeWidth={1.5}
                  className="splash-icon icon-instagram"
                />
              </a>
            </div>
            <div className="splash-info-items-wrapper">
              <div className="splash-info-item">
                <IconMapPin size={16} strokeWidth={1.7} />
                <p className="splash-info-text">Berkeley, CA</p>
              </div>
              <div className="splash-info-item">
                <IconMail size={16} strokeWidth={1.7} />
                <p className="splash-info-text">jamesfeng123@gmail.com</p>
              </div>
            </div>
            <div className="sl-list">
              <div className="sl-list-item">
                <p>Product designer + developer.</p>
              </div>
              <div className="sl-list-item">
                <p>Loves to build, and builds fast.</p>
              </div>
              <div className="sl-list-item">
                <p>
                  <span>Big on&nbsp;</span>
                  <a
                    onClick={() => handleLinkClick('Penguins')}
                    className="link link-penguins"
                    href="https://platform.vox.com/wp-content/uploads/sites/2/chorus/uploads/chorus_asset/file/15231691/453801468.0.0.1421786380.jpg"
                    target="_blank"
                    rel="noreferrer"
                  >
                    penguins.
                  </a>
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            className="splash-divider"
            initial={{
              scale: 0.4,
              opacity: 0,
              y: -16,
            }}
            animate={{
              scale: 1,
              opacity: 0.2,
              y: 0,
            }}
            transition={{
              delay: 0.2,
              duration: 0.6,
            }}
          />
          <motion.div
            className="splash-right"
            initial={{
              opacity: 0,
              x: -24,
            }}
            animate={{
              opacity: 1,
              filter: 'blur(0px)',
              x: 0,
            }}
            transition={{
              delay: 0.4,
              duration: 0.67,
              timing: 'ease-in',
            }}
          >
            <div className="sr-item">
              <div className="sr-item-wrapper">
                <div className="blinker blinker-wefunder" />
                <p className="sr-item-desc">
                  <span>Design Engineer @&nbsp;</span>
                  <a
                    onClick={() => handleLinkClick('Wefunder splash')}
                    className="link link-wefunder"
                    href="https://www.wefunder.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Wefunder
                  </a>
                </p>
              </div>
              <p className="sr-item-dates">Nov 2024 – Present</p>
            </div>
            <div className="sr-item">
              <div className="sr-item-wrapper">
                <p className="sr-item-desc">
                  <span>Built&nbsp;</span>
                  <a
                    onClick={() => handleLinkClick('FestiPlannr splash')}
                    className="link link-festiplannr"
                    href="https://www.festiplannr.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    festiplannr.com
                  </a>
                </p>
              </div>
              <p className="sr-item-dates">Jun 2024 – Aug 2024</p>
            </div>
            <div className="sr-item">
              <div className="sr-item-wrapper">
                <p className="sr-item-desc">
                  <span>Senior Product Designer @&nbsp;</span>
                  <a
                    onClick={() => handleLinkClick('Everlaw splash')}
                    className="link link-everlaw"
                    href="https://www.everlaw.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Everlaw
                  </a>
                </p>
              </div>
              <p className="sr-item-dates">Aug 2020 – Oct 2024</p>
            </div>
            <div className="sr-item">
              <p className="sr-item-desc">
                <span>Studied computer science @&nbsp;</span>
                <a
                  onClick={() => handleLinkClick('UPenn splash')}
                  className="link link-upenn"
                  href="https://www.upenn.edu"
                  target="_blank"
                  rel="noreferrer"
                >
                  Penn
                </a>
              </p>
              <p className="sr-item-dates">2016 – 2020</p>
            </div>
          </motion.div>
        </div>
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            delay: 0.8,
            duration: 0.8,
            timing: 'ease-in',
          }}
          className="scroll-down-icon back-to-top-wrapper"
          onClick={() => {
            document
              .querySelector('.u-first-section')
              .scrollIntoView({ behavior: 'smooth' })
            handleActionClick('See more')
          }}
        >
          <IconArrowDown
            size={20}
            strokeWidth={1.5}
            className="back-to-top-icon"
          />
          <button className="back-to-top-button">My work</button>
        </motion.div>
      </div>
      <div className="u-section u-first-section">
        <div className="u-header-wrapper">
          <div className="u-title-wrapper">
            <div className="u-title-divider u-title-left" />
            <a
              className="u-title link link-festiplannr"
              href="https://www.festiplannr.com"
              target="_blank"
              rel="noreferrer"
            >
              festiplannr.com
            </a>
            <div className="u-title-divider u-title-right" />
          </div>
          <div className="u-subtitle">Jun – Aug 2024</div>
          <div className="u-desc">
            Solo project. 0 to shipped in 6 weeks.
            <br />
            Thousands of schedules, groups, and lock screen images created for
            dozens of festivals and counting.
          </div>
        </div>
        <video
          src="/assets/fp_compressed.mp4"
          autoPlay
          loop
          muted
          playsInline
          className="u-video"
        />
        <div className="u-imgsets">
          <div className="u-imgset">
            {fpSet1.map((img, index) => (
              <div className="u-img-wrapper" key={index}>
                <img src={img} className="u-img" />
              </div>
            ))}
          </div>
          <div className="u-imgset">
            {fpSet2.map((img, index) => (
              <div className="u-img-wrapper" key={index}>
                <img src={img} className="u-img" />
              </div>
            ))}
          </div>
        </div>
        <div className="u-testimonials">
          <Marquee gradient={true} speed={90} gradientColor={'#191919'}>
            {testimonialsData.map((testimonial, index) => (
              <div key={index} className="testimonial-item">
                {testimonial}
              </div>
            ))}
          </Marquee>
        </div>
      </div>
      <div className="u-section u-new-section">
        <div className="u-header-wrapper">
          <div className="u-title-wrapper">
            <div className="u-title-divider u-title-left" />
            <div className="u-title">Wefunder</div>
            <div className="u-title-divider u-title-right" />
          </div>
          <div className="u-subtitle">Nov 2024 – Present</div>
          <div className="u-desc">
            Sole designer at Wefunder — designing + shipping to prod.
            <br />
            Built{' '}
            <a
              href="https://communityround.com/"
              target="_blank"
              rel="noreferrer"
              className="link link-wefunder"
            >
              communityround.com
            </a>{' '}
            in a 2 week sprint.
            <br />
            Currently: Building out a design system + improving the core
            investment flow.
          </div>
        </div>
        <video
          src="/assets/wf_compressed.mp4"
          autoPlay
          loop
          muted
          playsInline
          className="u-video"
        />
      </div>
      <div className="u-section u-new-section">
        <div className="u-header-wrapper">
          <div className="u-title-wrapper">
            <div className="u-title-divider u-title-left" />
            <div className="u-title">Everlaw</div>
            <div className="u-title-divider u-title-right" />
          </div>
          <div className="u-subtitle">Aug 2020 – Oct 2024</div>
          <div className="u-desc">
            Design lead for EverlawAI Assistant (genAI feature set), Everlaw's
            top product priority for 2023/24.
            <br />
            Also designed dozens of other AI and dataviz features.
          </div>
        </div>
        <div className="u-videos">
          <video
            src="/assets/cv_compressed.mp4"
            autoPlay
            loop
            muted
            playsInline
            className="u-double-video"
          />
          <video
            src="/assets/wa_compressed.mp4"
            autoPlay
            loop
            muted
            playsInline
            className="u-double-video"
          />
        </div>
        <div className="u-links">
          <div className="u-links-section">
            <div className="u-links-header">
              Some blog posts about stuff I designed:
            </div>
            <a
              onClick={() => handleLinkClick('EverlawAI blog')}
              className="link link-everlaw"
              href="https://www.everlaw.com/product/everlaw-ai-assistant"
              target="_blank"
              rel="noreferrer"
            >
              Everlaw
              <span style={{ verticalAlign: 'super', fontSize: '10px' }}>
                AI
              </span>{' '}
              Assistant
            </a>
            <a
              className="link link-everlaw"
              href="https://www.everlaw.com/blog/ai-and-law/introducing-multi-matter-models-by-everlaw/"
              target="_blank"
              rel="noreferrer"
            >
              Introducing Multi-Matter Models by Everlaw
            </a>
            <a
              onClick={() => handleLinkClick('CommViz blog')}
              className="link link-everlaw"
              href="https://www.everlaw.com/blog/ediscovery-software/introducing-communication-visualizer/"
              target="_blank"
              rel="noreferrer"
            >
              Introducing Communication Visualizer by Everlaw
            </a>
          </div>
          <div className="u-links-section">
            <div className="u-links-header">This is what I sound like:</div>
            <a
              onClick={() => handleLinkClick('CommViz video')}
              className="link link-everlaw"
              href="https://vimeo.com/849634956?share=copy#t=231.881"
              target="_blank"
              rel="noreferrer"
            >
              Communication Visualizer: Conceptual overview
            </a>
          </div>
        </div>
      </div>
      <div className="back-wrapper">
        <div
          className="back-to-top-wrapper"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            handleActionClick('Scroll to top')
          }}
        >
          <IconArrowUp
            size={20}
            strokeWidth={1.5}
            className="back-to-top-icon"
          />
          <button className="back-to-top-button">Back to top</button>
        </div>
      </div>
      <div className="u-footer">
        <div className="u-footer-icons">
          <a
            onClick={() => handleLinkClick('LinkedIn')}
            href="https://www.linkedin.com/in/james-feng/"
            target="_blank"
            rel="noreferrer"
          >
            <IconBrandLinkedin
              size={28}
              strokeWidth={1.5}
              className="splash-icon icon-linkedin"
            />
          </a>
          <a
            onClick={() => handleLinkClick('Instagram')}
            href="https://www.instagram.com/famousjenga"
            target="_blank"
            rel="noreferrer"
          >
            <IconBrandInstagram
              size={28}
              strokeWidth={1.5}
              className="splash-icon icon-instagram"
            />
          </a>
        </div>
        <div className="u-footer-header">
          <div className="u-footer-title">James Feng</div>
          <div className="u-footer-desc">Product designer + developer</div>
          <div className="u-footer-email">jamesfeng123@gmail.com</div>
        </div>
      </div>
      {/* <FestiPlannr
        scrollProgress={scrollProgress}
        setScrollProgress={setScrollProgress}
      />
      <Everlaw /> */}
    </div>
  )
}

export default Home
